import Vue from 'vue'
import { Button,Input,CarouselItem,Pagination,Drawer,Message,Carousel,Calendar,Backtop,Row,Col,Image } from 'element-ui'


Vue.use(Button)
Vue.use(Input)
Vue.use(Pagination)
Vue.use(CarouselItem)
Vue.use(Drawer)
Vue.use(Carousel)
Vue.use(Calendar)
Vue.use(Backtop)
Vue.use(Row)
Vue.use(Col)
Vue.use(Image)
Vue.prototype.$message = Message


