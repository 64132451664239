import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navindex:0,
    twocateindex:0,
    cateId:0
  },
  getters: {
  },
  mutations: {
    change(state,step){
        state.navindex = step
    },
    changetwo(state,step){
      state.twocateindex = step
    },
    changeCateId(state,step){
      state.cateId = step
    }
  },
  actions: {
  },
  modules: {
  }
})
