<template>
    <div id="app" ref="app">
        <Headers></Headers>

        <div :style="{minHeight: clientHeight-136 +'px'}">
            <router-view></router-view>
        </div>
        <el-backtop :visibility-height="500">
            <div style="{
        height: 100%;
        width: 100%;
        border
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: #1989fa;
      }">
                UP
            </div>
        </el-backtop>
        <Footers></Footers>

    </div>
</template>

<script>
import _ from 'lodash'
// import HelloWorld from './components/HelloWorld.vue'
import Footers from "./components/common/footers.vue";
import Headers from "./components/common/headers.vue";

export default {
    name: "app",
    components: {
        Footers,
        Headers,
    },
    data() {
        return {
            clientHeight: 0,
        };
    },
    created() {
        
    },
    mounted () {
    // this.$nextTick(() => {
    //   const $app = this.$refs.app
    //   const standardScale = 1080 / 1920

    //   window.addEventListener(
    //     'resize',
    //     _.debounce(function () {
    //       const docHeight = document.body.clientHeight
    //       const docWidth = document.body.clientWidth

    //       if (docWidth < 1680) {
    //         const currentScale = docHeight / docWidth
    //         let [scale, translate] = [0, 0]
    //         if (currentScale < standardScale) {
    //           // 以高度计算
    //           scale = docHeight / 1080
    //           const shouleWidth = 1920 * scale
    //           const offsetWidth = docWidth - shouleWidth
    //           translate = offsetWidth > 0 ? `translate(${offsetWidth / 2}px, 0)` : ''
    //         } else {
    //           // 以宽度计算
    //           scale = docWidth / 1920
    //           const shouleHeight = 1080 * scale
    //           const offsetHeight = docHeight - shouleHeight
    //           translate = offsetHeight > 0 ? `translate(0, ${offsetHeight / 2}px)` : ''
    //         }
    //         console.log(translate)
    //         $app.style.cssText = `
    //         transform: scale(${scale}) ${translate};
    //         transform-origin: top left;
    //         min-width: 1920px;
    //         min-height: 1080px;
    //       `
    //       } else {
    //         $app.style.cssText = ''
    //       }
    //     }),
    //     200
    //   )

    //   if (document.createEvent) {
    //     var event = document.createEvent('HTMLEvents')
    //     event.initEvent('resize', true, true)
    //     window.dispatchEvent(event)
    //   } else if (document.createEventObject) {
    //     window.fireEvent('onresize')
    //   }
    // })
  },

    methods: {
        
    },
};
</script>

<style>
/* #app {
  width: 100vw;
  height: 100vh;
  background: #124840;
 
} */



</style>
