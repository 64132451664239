import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/css/public.css'
// import "lib-flexible/flexible.js";
import './plugins/element.js'
import store from './store'

import i18n from './utils/vueIN'

import config from '@/network/config-util.js' // 引入配置文件

Vue.prototype.$BaseUrl = config;
//自定义方法 tool
import VueTool from '@/utils/tool.js'
Vue.prototype.$VueTool = VueTool;
import VueLazyload from 'vue-lazyload'
// import BaiduMap from 'vue-baidu-map'

import "video.js/dist/video-js.css";

Vue.use(VueLazyload,{
  preload: 1.8, // 表示lazyload元素距离底部距离百分比
  loading: require('@/assets/loading.png'), // 正在加载时显示的图片路径
  error: require('@/assets/loadError.png'), // 图片加载失败时显示的图片路径
  attempt: 5, // 图片加载失败后重试次数，默认3
})
// Vue.use(BaiduMap, {
//   // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//   ak: '3SgZ6VTII9eTql8qoeeLqUxGsRfY9hK3'
// })
//导入仓库
// import store from "./store/index"
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,// 挂载
  render: h => h(App)
}).$mount('#app')
