import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound from '../views/NotFound.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/admissionsView',
    name: 'admissionsView',
    component: () => import( '../views/AdmissionsView.vue')
  },
  {
    path: '/courseView',
    name: 'courseView',
    component: () => import('../views/CourseView.vue')
  },
  {
    path: '/serviceView',
    name: 'serviceView',
    component: () => import( '../views/ServiceView.vue')
  },
  {
    path: '/newsView',
    name: 'newsView',
    component: () => import( '../views/NewsView.vue')
  },{
    path: '/activityView',
    name: 'activityView',

    component: () => import('../views/ActivityView.vue')
  },{
    path: '/joinView',
    name: 'joinView',
    component: () => import('../views/JoinView.vue')
  },{
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },{
    path: '/contactView',
    name: 'contactView',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('../views/calendar.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
  
  // 给vuex 添加数据 to.meta
  // if(to.fullPath != '/'){
  //   if(sessionStorage.getItem("navindex")){

  //     console.log("不跳转");
  //   }
  //   else{
  //     next('/')
  //     console.log("跳转根路径");
  //   }
  // }
  // else{
  //   next()
  // }
  
})



export default router
