import { get, post } from '@/network/http-util.js'
import { returnMsg } from '@/network/return-util.js'

export const getHomeData = async data => await returnMsg(get('hello', data))
// 首页轮播图
export const getBanner = data => post('/index/banner', data)
export const test = data => post('/index/banner', data)
// 首页校园新闻轮播
export const getnewsBanner = data => post('/index/news', data)
// 首页学校课程
export const getadmissions = data => post('/index/CurriculumCat', data)
// 首页校园活动
export const getActivityCat = data => post('/index/ActivityCat', data)

// 招生分类
export const getAdmissionsCatInfo = data => post('/getAdmissionsCatInfo', data)
// 招生分类详情
export const getAdmissionsDetail = data => post('/getAdmissionsDetail', data)

// 课程分类
export const getCurriculumCatInfo = data => post('/getCurriculumCatInfo', data)
// 课程详情
export const getCurriculumDetail = data => post('/getCurriculumDetail', data)

// 服务分类
export const getServiceCatInfo = data => post('/getServiceCatInfo', data)
// 服务详情
export const getServiceDetail = data => post('/getServiceDetail', data)

// 校园新闻分类
export const getNewsCatInfo = data => post('/getNewsCatInfo', data)
// 校园新闻列表
export const getNewsList = data => post('/getNewsList', data)
// 校园新闻详情
export const getNewsDetail = data => post('/getNewsDetail', data)

// 校园活动分类
export const getActivityCatInfo = data => post('/getActivityCatInfo', data)
// 校园活动列表
export const getActivityList = data => post('/getActivityList', data)
// 校园活动详情
export const getActivityDetail = data => post('/getActivityDetail', data)

// 加入我们分类
export const getJoinCatInfo = data => post('/getJoinCatInfo', data)
// 加入我们详情
export const getJoinDetail = data => post('/getJoinDetail', data)

// 关于我们分类
export const getAboutCatInfo = data => post('/getAboutCatInfo', data)
// 关于我们列表
export const getAboutList = data => post('/getAboutList', data)
// 关于我们详情
export const getAboutDetail = data => post('/getAboutDetail', data)

//联系我们
export const getContactInfo = data => post('/getContactInfo', data)

// 更改语言
export const changeLang = data => post('/changeLang', data)

// 获取系统配置
export const getCollocation = data => post('/collocation', data)

//获取友情链接
export const getLink = data => post('/index/getLink', data)

// 获取校园年历
export const getYearCalendar = data => post('/getYearCalendar', data)
// 获取日历安排
export const getCalendar = data => post('/getCalendar', data)
// 获取日历分类
export const getCalendarCatInfo = data => post('/getCalendarCatInfo', data)

// 获取首页底部导航栏

export const getNavbarFooter = data => post('/index/NavbarFooter', data)











