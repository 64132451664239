// 创建文件en.js
const en = {
    Home: 'Home',
    Admissions: 'Admissions',
    Course:'Course',
    Service:'Service',
    news:'News',
    Activity:'Activity',
    Join:'Join',
    About:'About',
    Contact:'Contact',
    language: {
        zh: '中文',
        en: 'English',
    },
    isCN:'false',
    back:'Back',
    detail:'Detail',
    source:'source',
    pre:'The previous one',
    next:'The next one',
    join:'Join',
    tel:'Enquiry Hotline',
    tel2:'Contact Number',
    tel3:'Email Address',
    tel4:'School Website',
    tel5:'Address of School',
    tel6:'The personnel',
    tel7:'Recruitment',
    tel8:'Scan the official wechat account of your school',
    calendar:'School calendar'
}
export default en;
