<template>
    <div>
        <!-- pc -->
        <div class="content" v-if="!isMobile_pc">
            <div class="_left">
                <img class="img" src="../../assets/logoIcon.png" alt="">
            </div>
            <div class="_right">
                <div class="inputAnd18">

                    <div class="input_search">
                        <el-input class="input" style="" v-model="keyword" placeholder="Please enter a keyword to search"></el-input>
                        <div class="search">
                            <img src="../../assets/search.png" alt="">
                        </div>
                    </div>
                    <div class="v18">
                        <span @click="change('Cn')">CN</span> | <span @click="change('En')">EN</span>
                    </div>
                </div>

                <div class="nav">
                    <router-link class="productHover" to="/" :class="{ active: navindex == 0 }" @click.native="changeTab(0)">{{ $t('Home') }}
                    </router-link>
                    <router-link class="productHover" replace to="/admissionsView" :class="{ active: navindex == 1 }" @click.native="changeTab(1)">{{$t('Admissions')}}
                        <div class="zilei">
                            <span></span>
                            <div class="fenlei" v-for="(item,index) in itemList" :key="index" @click.stop="sonClick(index,item.catId,1)">
                                {{ $t('isCN') == 'true' ?  item.catName : item.catNameEn}}
                            </div>
                        </div>
                    </router-link>
                    <router-link class="productHover" replace to="/courseView" :class="{ active: navindex == 2 }" @click.native="changeTab(2)">{{$t('Course')}}
                        <div class="zilei">
                            <span></span>
                            <div class="fenlei" v-for="(item,index) in itemList1" :key="index" @click.stop="sonClick(index,item.catId,2)">
                                {{ $t('isCN') == 'true' ?  item.catName : item.catNameEn}}
                            </div>
                        </div>
                    </router-link>
                    <router-link class="productHover" replace to="/serviceView" :class="{ active: navindex == 3 }" @click.native="changeTab(3)">{{$t('Service')}}
                        <div class="zilei">
                            <span></span>
                            <div class="fenlei" v-for="(item,index) in itemList2" :key="index" @click.stop="sonClick(index,item.catId,3)">
                                {{ $t('isCN') == 'true' ?  item.catName : item.catNameEn}}
                            </div>
                        </div>
                    </router-link>
                    <router-link class="productHover" replace to="/newsView" :class="{ active: navindex == 4 }" @click.native="changeTab(4)">{{$t('news')}}
                        <div class="zilei">
                            <span></span>
                            <div class="fenlei" v-for="(item,index) in itemList3" :key="index" @click.stop="sonClick(index,item.catId,4)">
                                {{ $t('isCN') == 'true' ?  item.catName : item.catNameEn}}
                            </div>
                        </div>
                    </router-link>
                    <router-link class="productHover" replace to="/activityView" :class="{ active: navindex == 5 }" @click.native="changeTab(5)">{{$t('Activity')}}
                        <div class="zilei">
                            <span></span>
                            <div class="fenlei" v-for="(item,index) in itemList4" :key="index" @click.stop="sonClick(index,item.catId,5)">
                                {{ $t('isCN') == 'true' ?  item.catName : item.catNameEn}}
                            </div>
                        </div>
                    </router-link>
                    <router-link class="productHover" replace to="/joinView" :class="{ active: navindex == 6 }" @click.native="changeTab(6)">{{$t('Join')}}
                        <div class="zilei">
                            <span></span>
                            <div class="fenlei" v-for="(item,index) in itemList5" :key="index" @click.stop="sonClick(index,item.catId,6)">
                                {{ $t('isCN') == 'true' ?  item.catName : item.catNameEn}}
                            </div>
                        </div>
                    </router-link>
                    <router-link class="productHover" replace to="/about" :class="{ active: navindex == 7 }" @click.native="changeTab(7)">{{$t('About')}}
                        <div class="zilei">
                            <span></span>
                            <div class="fenlei" v-for="(item,index) in itemList6" :key="index" @click.stop="sonClick(index,item.catId,7)">
                                {{ $t('isCN') == 'true' ?  item.catName : item.catNameEn}}
                            </div>
                        </div>
                    </router-link>
                    <router-link class="productHover" replace to="/contactView" :class="{ active: navindex == 8 }" @click.native="changeTab(8)">{{$t('Contact')}}</router-link>

                </div>
            </div>
        </div>
        <!-- 移动 -->
        <div class="content_mobile" v-else>
            <a href="./" class="logo">
                <img src="../../assets/logoIcon.png"  style="width:100%"/>
            </a>
            <div class="menu-button" :class="{ cross: toggleMenu }" @click="toggleMenu = !toggleMenu">
                <div class="btnbox">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>
            </div>

            <!-- 侧栏菜单 -->
            <el-drawer :visible.sync="toggleMenu" :withHeader="false" size="50%">
                <div class="menu-pop">
                    <!-- <div class="height"></div> -->
                    <div class="menu-list">
                        <router-link replace to="/" :class="{ active: navindex == 0 }" @click.native="changeTab(0)">{{$t('Home')}}</router-link>
                        <router-link replace to="/admissionsView" :class="{ active: navindex == 1 }" @click.native="changeTab(1)">{{$t('Admissions')}}</router-link>
                        <router-link replace to="/courseView" :class="{ active: navindex == 2 }" @click.native="changeTab(2)">{{$t('Course')}}</router-link>
                        <router-link replace to="/serviceView" :class="{ active: navindex == 3 }" @click.native="changeTab(3)">{{$t('Service')}}</router-link>
                        <router-link replace to="/newsView" :class="{ active: navindex == 4 }" @click.native="changeTab(4)">{{$t('news')}}</router-link>
                        <router-link replace to="/activityView" :class="{ active: navindex == 5 }" @click.native="changeTab(5)">{{$t('Activity')}}</router-link>
                        <router-link replace to="/joinView" :class="{ active: navindex == 6 }" @click.native="changeTab(6)">{{$t('Join')}}</router-link>
                        <router-link replace to="/about" :class="{ active: navindex == 7 }" @click.native="changeTab(7)">{{$t('About')}}</router-link>
                        <router-link replace to="/contactView" :class="{ active: navindex == 8 }" @click.native="changeTab(8)">{{$t('Contact')}}</router-link>
                        <router-link replace to="/" @click.native="change('Cn')">CN</router-link>
                        <router-link replace to="/" @click.native="change('En')">EN</router-link>
                    </div>
                </div>
                <div class="close_right" @click="toggleMenu = !toggleMenu"></div>
            </el-drawer>
        </div>

    </div>

</template>

<script>
import {
    getAdmissionsCatInfo,
    getCurriculumCatInfo,
    getServiceCatInfo,
    getNewsCatInfo,
    getActivityCatInfo,
    getJoinCatInfo,
    getAboutCatInfo,
} from "@/network/test-api.js";
import { mapState } from "vuex";
import { changeLang } from "@/network/test-api.js";
export default {
    computed: {
        ...mapState(["navindex"]),
    },
    data() {
        return {
            keyword: "", //关键词
            toggleMenu: false, //侧面菜单开关
            itemList: [], //招生二级分类
            itemList1:[], //课程
            itemList2: [], //服务二级分类
            itemList3: [], //新闻二级分类
            itemList4: [], //活动二级分类
            itemList5: [], //加入我们二级分类
            itemList6: [], //关于我们二级分类
        };
    },
    mounted() {},
    created() {
        // 当前选中
        if (sessionStorage.getItem("navindex")) {
            this.$store.commit("change", sessionStorage.getItem("navindex"));
        }

        this.$VueTool.isMobile_pc()
            ? (this.isMobile_pc = true)
            : (this.isMobile_pc = false);
        if (sessionStorage.getItem("lang")) {
            if (sessionStorage.getItem("lang") == "En") {
                this.$i18n.locale = "en";
            } else {
                this.$i18n.locale = "cn";
            }
        } else {
            sessionStorage.setItem("lang", "Cn");
            this.$i18n.locale = "cn";
        }
        let datas1 = new FormData();
        if (sessionStorage.getItem("lang")) {
            datas1.append("lang_f", sessionStorage.getItem("lang"));
        } else {
            datas1.append("lang_f", "Cn");
        }
        // 招生二级分类
        getAdmissionsCatInfo(datas1)
            .then((res) => {
                if (res.status == 1) {
                    this.itemList = res.row;
                }
            })
            .catch((err) => {});
        //课程二级分类
        getCurriculumCatInfo(datas1).then((res=>{
            if(res.status ==1){
                this.itemList1 = res.row
            }
        }))
        // 服务二级分类
        getServiceCatInfo(datas1)
            .then((res) => {
                if (res.status == 1) {
                    this.itemList2 = res.row;
                }
            })
            .catch((err) => {});
        // 新闻二级分类
        getNewsCatInfo(datas1)
            .then((res) => {
                if (res.status == 1) {
                    this.itemList3 = res.row;
                }
            })
            .catch((err) => {});
        // 活动二级分类
        getActivityCatInfo(datas1)
            .then((res) => {
                if (res.status == 1) {
                    this.itemList4 = res.row;
                }
            })
            .catch((err) => {});
        // 加入我们二级分类
        getJoinCatInfo(datas1)
            .then((res) => {
                if (res.status == 1) {
                    this.itemList5 = res.row;
                }
            })
            .catch((err) => {});
        // 关于我们二级分类
        getAboutCatInfo(datas1)
            .then((res) => {
                if (res.status == 1) {
                    this.itemList6 = res.row;
                }
            })
            .catch((err) => {});
    },
    methods: {
        changeTab(e) {
            console.log("导航栏状态",e);
            sessionStorage.setItem("navindex", e);
            this.$store.commit("change", e);
           sessionStorage.removeItem('twocateindex')
            if (this.isMobile_pc) {
                this.toggleMenu = false;
            }
        },
        change(e) {
            // sessionStorage.setItem("navindex", 0);
            // this.$store.commit("change", 0);
            if (e == "Cn") {
                this.$i18n.locale = "cn";
                sessionStorage.setItem("lang", e);
            } else {
                this.$i18n.locale = "en";
                sessionStorage.setItem("lang", e);
            }
            this.$router.go(0);
        },
        sonClick(index, id,nav) {
            console.log("触发到子级");

            sessionStorage.setItem("navindex", nav);
            sessionStorage.setItem("twocateindex", index);
            sessionStorage.setItem("cateId", id);
            this.$store.commit("change", nav);
            this.$store.commit("changetwo", index);
            this.$store.commit("changeCateId", id);
        },
    },
};
</script>

<style scoped lang="scss">
.productHover:hover .zilei {
    opacity: 0.8;
    display: block;
}
.productHover:hover {
    cursor: pointer;
}
.productHover {
    position: relative;
    padding: 20px;
}

.zilei {
    background: #fff;
    position: absolute;
    top: 35px;
    left: -50%;
    /* width:100%; */
    /* padding: 20px; */
    color: #000;
    background-color: #fff;
    // box-shadow: 0 3px 29px -8px rgb(192 196 204 / 71%);
    border-radius: 6px;
    padding: 5px 0px;
    left: 50%;
    top: 54px;
    z-index: 999;
    display: none;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    // padding: 20px 60px;
    text-align: center;

    z-index: 999;
    list-style: none;
    // padding: 0;
}
.zilei span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 100%;
    height: 15px;
}
.zilei span::after {
    content: "";
    top: 5px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 0;
    height: 0;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
}

.fenlei {
    text-align: center;
    white-space: nowrap;
    margin-top: 10px;
    padding: 10px 30px;
    &:hover {
        color: rgba(190, 163, 126, 1);
    }
}
.active {
    color: rgba(190, 163, 126, 1) !important;
    pointer-events: none;
}

a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    // padding-right: 0.5rem /* 60/10 */;
    // white-space: nowrap;
    // margin-left: 60px;
}
// pc样式
.content {
    width: 100%;
    // height: 136px;
    // max-height: 30vmin;
    background: #124840;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 221px;
    ._left {
        // min-width: 3.64rem /* 36.4/10 */;
        width: 364px;
        height: 95px;
        .img {
            width: 100%;
            height: 100%;
        }
    }
    ._right {
        .inputAnd18 {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .input_search {
                width: 290.5px;
                height: 31.72px;
                border-radius: 15.86px;
                background: #fff;
                border: 1px solid #d2d2d2;
                display: flex;
                ::v-deep .input .el-input__inner {
                    width: 251px;
                    height: 31px;
                    border: none;
                    border-top-left-radius: 15.86px;
                    border-bottom-left-radius: 15.86px;
                }
                .search {
                    cursor: pointer;
                    width: 38.11px;
                    height: 31.82px;
                    background: #bea37e;
                    border-top-right-radius: 15.86px;
                    border-bottom-right-radius: 15.86px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 13.42px;
                        height: 12.99px;
                    }
                }
            }

            .v18 {
                font-size: 14px;
                color: #fff;
                margin-left: 49.53px;
                cursor: pointer;
            }
        }
        .nav {
            width: 836px;
            display: flex;
            justify-content: space-between;
            margin-top: 33.94px;
            white-space: nowrap;

            .nav_item {
                list-style: none;
                position: relative;
                padding: 20px;
                color: #fff;

                .tab {
                    padding: 30px 20px;
                    color: rgba(255, 255, 255, 1);
                    font-size: 16px;
                    font-weight: 400;
                }
                &:hover .sub_nav {
                    opacity: 1;
                }
                .sub_nav {
                    opacity: 0;
                    position: absolute;
                    left: 50%;
                    // top:0;
                    bottom: -60px;
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                    transform: translateX(-50%);
                    // padding: 20px 60px;
                    text-align: center;
                    width: 160px;
                    background: #fff;
                    z-index: 999;
                    list-style: none;
                    padding: 0;
                    .text {
                        text-align: center;
                        padding: 20px 0;
                        border-bottom: 1px solid #ccc;
                        &:hover {
                            color: #4c4c4c;
                        }
                    }
                }
            }

            a {
                &:hover {
                    color: #bea37e;
                }
            }
        }
    }
}
// 移动端样式
.content_mobile {
    width: 100%;
    height: 60px;
    // max-height: 30vmin;
    background: #124840;
    box-sizing: border-box;
    padding: 0 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
        img {
            // width: 1000px;
            height: 100%;
        }
    }
    .menu-button {
        width: 10%;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .btnbox {
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .bar {
            position: relative;
            display: block;
            width: 100%;
            height: 3.2px;
            background-color: #fff;
            border-radius: 6.56px;
            transition: 0.3s;
        }

        .cross .bar {
            background: #000;
        }

        .cross .bar:nth-of-type(1) {
            transform: translateY(5.56px) rotate(-45deg);
        }

        .cross .bar:nth-of-type(2) {
            opacity: 0;
        }

        .cross .bar:nth-of-type(3) {
            transform: translateY(-6.56px) rotate(45deg);
        }

        .menu-button .bar:nth-of-type(3) {
            margin-bottom: 0;
        }
    }
}

.menu-pop {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    .height {
        height: 300px;
        background: #fff;
    }
    .menu-list {
        display: flex;
        flex-direction: column;
        a {
            // font-size: px;
            text-align: center;
            padding: 15px 0;
            color: #000;
        }
        .active {
            background: #ccc;
        }
    }
}
</style>

<style>
.v-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #000;
}
</style>