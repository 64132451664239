<template>
    <div>
        <!-- pc -->
        <div class="home" v-if="!isMobile_pc">
            <!-- 轮播图 -->

            <!-- <div class="swiper"> -->
            <el-carousel height="730px">
                <el-carousel-item v-for="(item, index) in imageList" :key="index">
                    <img class="swiper_item" v-lazy="item.img" loading="lazy">
                </el-carousel-item>
            </el-carousel>

            <!-- 学校新闻 -->
            <div class="news">
                <div class="news_item">
                    <div class="newbg">
                        <div class="titled">
                            {{$t('isCN') == 'true' ? 'CAMPUS NEWS' : '学校新闻'}}
                        </div>
                        <div class="title">{{$t('isCN') == 'true' ? '学校新闻' :'CAMPUS NEWS'}}</div>
                    </div>

                    <div @click="clickLeft" class="iconDiv icon-left">
                        <i class="el-icon-caret-left"></i>
                    </div>

                    <div class="swiper_wai" ref="inforTitleR">
                        <swiper :options="swiperOptions" ref="mySwiper" v-if="beimg.length > 0">
                            <swiper-slide v-for="(item,index) in beimg" :key="index">

                                <div class="box" ref="box" @click="goNewsDetail(item.newsId)">
                                    <img :src="item.newsImg" alt="暂无图片">
                                    <div>
                                        <div class="date">{{$t('isCN') == 'true' ? '日期' : 'date'}} ：{{item.publicTime}}
                                        </div>
                                        <div class="Introduction">{{item.title}}</div>
                                    </div>
                                </div>

                            </swiper-slide>

                        </swiper>
                    </div>

                    <div @click="clickRight" class="iconDiv icon-right">
                        <i class="el-icon-caret-right"></i>
                    </div>
                </div>

                <div class="more" @click="gonews">
                    READ MORE
                </div>
            </div>

            <!-- 学校课程 -->
            <div class="kecheng">
                <div class="titled">
                    {{$t('isCN') == 'true' ?  'SCHOOL CURRICULUM' :'学校课程'}}
                </div>
                <div class="title">{{$t('isCN') == 'true' ? '学校课程' : 'SCHOOL CURRICULUM'}}</div>

                <div class="imgs" v-if="admiss != ''">
                    <div class="item" v-for="(item,index) in admiss" :key="index" @click="goCourse(item.catId)">
                        <img v-lazy="item.img" alt="">
                        <div class="text">
                            <div class="c-title"> {{$t('isCN') == 'true' ?  item.catName : item.catNameEn}}</div>
                            <div class="c-titleen">{{$t('isCN') == 'true' ?  item.catNameEn : item.catName}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 学校活动 -->
            <div class="activity">
                <div class="titled">
                    {{$t('isCN') == 'true' ?  'CAMPUS ACTIVITY' :'学校活动'}}
                </div>
                <div class="title">{{$t('isCN') == 'true' ? '学校活动' : 'CAMPUS ACTIVITY'}}</div>
                <div class="calendar" @click="calendar">
                    {{ $t('calendar')}}
                </div>
                <div class="activity_box" v-if="activityCat != ''">
                    <!-- <div class="left_box">
                        <div class="one">
                            <img class="imgs" :src="Url + activityCat[4].img" alt="">
                            <div class="two">{{activityCat[4].catName}}</div>
                        </div>
                        <div class="one">
                            <img class="imgs" :src="Url + activityCat[3].img" alt="">
                            <div class="two">{{activityCat[3].catName}}</div>

                        </div>
                    </div> -->
                    <div class="mid_box" @click="sonClick(activityCatId,5)">
                        <img class="imgs" :src="pic1" alt="">
                        <div class="two">
                            {{ $t('isCN') == 'true' ?  pic5 : pic55}}
                        </div>
                    </div>
                    <div class="mid_box">
                        <!-- @click="sonClick1(activityCatId1,5)" -->
                        <!-- <img class="imgs" :src="pic3" alt="" > -->
                        <div class="imgs">
                            <XgPlayer :id="'xgPlayer'"  :video-url="pic2" :poster="pic3"/>
                        </div>
                        <!-- <img class="imgs" :src="Url + activityCat[2].img" alt=""> -->
                        <div class="two">
                            {{ $t('isCN') == 'true' ?  pic4 : pic44}}
                        </div>
                    </div>

                    <!-- <div class="right_box">
                        <div class="one"><img class="imgs" :src="Url + activityCat[1].img" alt="">
                            <div class="two">{{activityCat[1].catName}}</div>

                        </div>
                        <div class="one"><img class="imgs" :src="Url + activityCat[0].img" alt="">
                            <div class="two">{{activityCat[0].catName}}</div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        <!-- 移动 -->
        <div class="home_mobile" v-else>
            <!-- 轮播图 -->
            <el-carousel height="200px">
                <el-carousel-item v-for="(item, index) in imageList" :key="index">
                    <img class="swiper_item" style="height:200px" v-lazy="item.img" loading="lazy">
                </el-carousel-item>
            </el-carousel>
            <!-- <div class="swiper" ref="inforTitleR">
                <swiper :options="swiperOptions" ref="mySwiper">
                    <swiper-slide>
                        <img v-for="(item, index) in imageList" :key="index" style="width:100%"   v-lazy="item.img">
                    </swiper-slide>

                </swiper>
            </div> -->
            <!-- 学校新闻 -->
            <div class="news">
                <div class="newbg">
                    <div class="titled">
                        {{$t('isCN') == 'true' ? 'CAMPUS NEWS' : '学校新闻'}}
                    </div>
                    <div class="title">{{$t('isCN') == 'true' ?  '学校新闻' :'CAMPUS NEWS'}}</div>
                </div>
                <div class="swiper_wai" ref="inforTitleR">
                    <swiper :options="swiperOptions1" ref="mySwiper" v-if="beimg.length > 0">
                        <swiper-slide v-for="(item,index) in beimg" :key="index">

                            <div class="box" ref="box" @click="goNewsDetail(item.newsId)">
                                <img :src="item.newsImg" alt="暂无图片">
                                <div>
                                    <div class="date">{{$t('isCN') == 'true' ? '日期' : 'date'}} ：{{item.publicTime}}
                                    </div>
                                    <div class="Introduction">{{item.title}}</div>
                                </div>
                            </div>

                        </swiper-slide>

                    </swiper>
                </div>
                <!-- <div class="swiper_wai">
                    <div class="swiper_nei" ref="sw">
                        <div class="box" v-for="(item,index) in beimg" :key="index" ref="box">
                            <img v-lazy="item.newsImg" alt="暂无图片">
                            <div>
                                <div class="date">{{$t('isCN') == 'true' ? '日期' : 'date'}}：{{item.addTime}}</div>
                                <div class="Introduction">{{item.title}}</div>
                            </div>
                        </div>

                    </div>
                </div> -->

            </div>
            <!-- 学校课程 -->
            <div class="kecheng">
                <div class="titled">
                    {{$t('isCN') == 'true' ?  'SCHOOL CURRICULUM' :'学校课程'}}
                </div>
                <div class="title"> {{$t('isCN') == 'true' ?  '学校课程' : 'SCHOOL CURRICULUM'}}</div>

                <div class="imgs">
                    <div class="item" v-for="(item,index) in admiss" :key="index" @click="goCourse(item.catId)">
                        <img v-lazy="item.img" alt="">
                        <div class="text">
                            <div class="c-title">{{$t('isCN') == 'true' ?  item.catName : item.catNameEn}}</div>
                            <div class="c-titleen">{{$t('isCN') == 'true' ?  item.catNameEn : item.catName}}</div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- 学校活动 -->
            <div class="activity">
                <div class="titled">
                    {{$t('isCN') == 'true' ?  'CAMPUS ACTIVITY' :'学校活动'}}
                </div>
                <div class="title">{{$t('isCN') == 'true' ? '学校活动' : 'CAMPUS ACTIVITY'}}</div>
                <div class="calendar" @click="calendar">
                    {{ $t('calendar')}}
                </div>
                <div class="activity_box" v-if="activityCat != ''">
                    <!-- <div class="left_box">
                        <div class="one">
                            <img class="imgs" :src="Url + activityCat[4].img" alt="">
                            <div class="two">{{activityCat[4].catName}}</div>
                        </div>
                        <div class="one">
                            <img class="imgs" :src="Url + activityCat[3].img" alt="">
                            <div class="two">{{activityCat[3].catName}}</div>

                        </div>
                    </div> -->
                    <div class="mid_box" @click="sonClick(activityCatId,5)">
                        <img class="imgs" :src="pic1" alt="">
                        <div class="two" style="top:-30px;">
                            {{ $t('isCN') == 'true' ?  pic5 : pic55}}
                        </div>
                    </div>
                    <div class="mid_box" style="margin-top:30px;">
                        <!-- @click="sonClick1(activityCatId1,5)" -->
                        <!-- <img class="imgs" :src="pic3" alt="" > -->
                        <div class="imgs">
                            <XgPlayer :id="'xgPlayer'"  :video-url="pic2" :poster="pic3"/>
                        </div>
                        
                        <!-- <video id="cameraMonitoringVideo" class="imgs vjs-default-skin"  controls>
                            <source :src="pic2" type="video/mp4" :poster="pic3">
                        </video> -->
                        <!-- <video ref="videoRef" class="imgs video-js vjs-default-skin" controls :poster="pic3">
                            <source :src="pic2" />
                        </video> -->
                        <!-- <img class="imgs" :src="Url + activityCat[2].img" alt=""> -->
                        <div class="two" style="top:18px">
                            {{ $t('isCN') == 'true' ?  pic4 : pic44}}
                        </div>
                    </div>

                    <!-- <div class="right_box">
                        <div class="one"><img class="imgs" :src="Url + activityCat[1].img" alt="">
                            <div class="two">{{activityCat[1].catName}}</div>

                        </div>
                        <div class="one"><img class="imgs" :src="Url + activityCat[0].img" alt="">
                            <div class="two">{{activityCat[0].catName}}</div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import XgPlayer from '@/components/xgPlayer.vue'
    import {
        swiper,
        swiperSlide /* rest swiper/vue API... */ ,
    } from "vue-awesome-swiper";
    import "swiper/dist/css/swiper.css";
    import videojs from "video.js";
    import 'videojs-contrib-hls'
    import "@videojs/http-streaming"
    import {
        getBanner,
        getnewsBanner,
        getadmissions,
        getActivityCat,
        test

    } from "@/network/test-api.js";

    export default {
        components: {
            swiper,
            swiperSlide,
            XgPlayer
        },
        name: "HomeView",

        data() {
            return {
                swiperOptions: {
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay
                        waitForTransition: true, //等待过渡完毕。自动切换会在slide过渡完毕后才开始计时
                    },
                    grabCursor: true,
                    slidesPerView: 3,
                    height: 304,
                    speed: 1000, //滚动速度
                    freeMode: true,
                    loop: true,
                    navigation: {
                        nextEl: ".icon-right",
                        prevEl: ".icon-left",
                    },
                },
                swiperOptions1: {
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay
                        waitForTransition: true, //等待过渡完毕。自动切换会在slide过渡完毕后才开始计时
                    },
                    grabCursor: true,
                    slidesPerView: 2,
                    height: 304,
                    speed: 1000, //滚动速度
                    freeMode: true,
                    loop: true,
                    navigation: {
                        nextEl: ".icon-right",
                        prevEl: ".icon-left",
                    },
                },
                ition: 1,
                Url: "",
                detail: false,
                timer: null, //定时轮询
                currentIndex: 0,
                imageList: [],
                beimg: [], //新闻
                admiss: [], //课程
                activityCat: [], //活动
                activityCatId: '',
                activityCatId1: '',
                pic1: '',
                pic2: '',
                pic3: '',
                pic4: '',
                pic5: '',
                pic6: '',
                pic44: '',
                pic55: '',
                x: 0,
                height: "730px",
                imgWidth: 260,
                timer: null,
                theSpeed: this.speed,
                imgWidth: 260,
                theDirection: "left",
                player: null
            };
        },
        mounted() {
            //页面加载完成后执行方法（启动定时器）
            clearInterval(this.timer);


        },
        beforeDestroy() {
            if (this.player != null) {
                this.player.dispose() // dispose()会直接删除Dom元素
            }
        },
        created() {




            this.$VueTool.isMobile_pc() ?
                (this.isMobile_pc = true) :
                (this.isMobile_pc = false);
            this.Url = this.$BaseUrl.baseUrl.prod;
            let datas1 = new FormData();

            if (sessionStorage.getItem("lang")) {
                datas1.append("lang_f", sessionStorage.getItem("lang"));
            } else {
                datas1.append("lang_f", "Cn");
            }
            getBanner(datas1)
                .then((res) => {
                    if (res.status == 1) {
                        this.imageList = res.row;
                    }
                })
                .catch((err) => {});
            // 首页校园新闻轮播
            getnewsBanner(datas1)
                .then((res) => {
                    if (res.status == 1) {
                        this.beimg = res.row;
                    }
                })
                .catch((err) => {});
            // 首页学校课程
            getadmissions(datas1)
                .then((res) => {
                    if (res.status == 1) {
                        this.admiss = res.row;
                    }
                })
                .catch((err) => {});

            // 首页校园活动
            getActivityCat(datas1)
                .then((res) => {
                    if (res.status == 1) {
                        res.row = res.row.reverse()
                        this.activityCat = res.row;
                        this.activityCatId = res.row[0].catId
                        this.activityCatId1 = res.row[1].catId



                        this.pic1 = res.row[0].img
                        this.pic2 = res.row[1].video
                        this.player = videojs('cameraMonitoringVideo', {
                            bigPlayButton: true,
                            textTrackDisplay: false,
                            posterImage: true,
                            errorDisplay: false,
                            controlBar: true,
                            muted: true //静音模式 、、 解决首次页面加载播放。
                        }, function () {
                            // this.play() // 自动播放
                        })
                        this.pic3 = res.row[1].img
                        this.pic4 = res.row[1].catName
                        this.pic44 = res.row[1].catNameEn
                        this.pic5 = res.row[0].catName
                        this.pic55 = res.row[0].catNameEn
                    }
                })
                .catch((err) => {});
        },
        beforeDestroy() {
            //页面关闭前关闭定时器 （这个才有用）
        },
        methods: {
            sonClick(id, nav) {
                let url;
                nav = 5;
                url = "/activityView";


                console.log("触发到子级", 0, id, nav);
                sessionStorage.setItem("navindex", nav);
                sessionStorage.setItem("twocateindex", 0);
                sessionStorage.setItem("cateId", id);
                this.$store.commit("change", nav);
                this.$store.commit("changetwo", 0);
                this.$store.commit("changeCateId", id);
                this.$router.replace(url);
                return
                this.$router.go(0)
                // chrome
                document.body.scrollTop = 0;
                // firefox
                document.documentElement.scrollTop = 0;
                // safari
                window.pageYOffset = 0;
            },
            sonClick1(id, nav) {
                let url;
                nav = 5;
                url = "/activityView";


                console.log("触发到子级", 0, id, nav);
                sessionStorage.setItem("navindex", nav);
                sessionStorage.setItem("twocateindex", 1);
                sessionStorage.setItem("cateId", id);
                this.$store.commit("change", nav);
                this.$store.commit("changetwo", 0);
                this.$store.commit("changeCateId", id);
                this.$router.replace(url);
                return
                this.$router.go(0)
                // chrome
                document.body.scrollTop = 0;
                // firefox
                document.documentElement.scrollTop = 0;
                // safari
                window.pageYOffset = 0;
            },


            calendar() {
                this.$router.replace({
                    path: "/calendar"
                });
                sessionStorage.setItem("navindex", 9);
                this.$store.commit("change", 9);
            },
            goCourse(id) {
                this.$router.replace({
                    path: "/courseView"
                });
                sessionStorage.setItem("id", id);
                sessionStorage.setItem("navindex", 2);
                this.$store.commit("change", 2);
            },

            goNewsDetail(id) {
                this.$router.replace({
                    path: "/newsView"
                });
                sessionStorage.setItem("id", id);
                sessionStorage.setItem("navindex", 4);
                this.$store.commit("change", 4);
            },
            gonews() {
                this.$router.replace("/newsView");
                sessionStorage.setItem("navindex", 4);
                this.$store.commit("change", 4);
            },
            clickLeft() {
                this.swiper.slidePrev();
            },
            clickRight() {
                this.swiper.slideNext();
            },
        },
    };
</script>
<style>
    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    .bg-purple {
        background: #d3dce6;
    }

    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
</style>
<style scoped lang="scss">
    .swiper-slide {
        // width: auto !important;
        // margin-right: 15px !important;
    }

    /* 清除li前面的圆点 */
    li {
        list-style-type: none;
    }

    /* 箭头图标 */
    .iconDiv {
        position: absolute;
        top: 60%;
        transform: translateY(-100%);
        width: 47px;
        height: 47px;
        // border: 1px solid #ccc;
        border-radius: 50%;
        background-color: #6e7e7b;
        line-height: 47px;
        text-align: center;
        font-size: 25px;
        cursor: pointer;
        color: #fff;
    }

    .iconDiv:hover {
        background-color: #134940;
    }

    .icon-left {
        left: 160px;
    }

    .icon-right {
        right: 160px;
    }

    /* 控制圆点 */

    .active {
        background-color: black !important;
    }

    .swiper_item {
        width: 100%;

    }

    .swiper_item[lazy="loading"] {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    // pc端
    .home {

        .swiper {
            // width: 100vw;
            height: 730px;
            display: flex;

            // overflow: hidden;
            .img {
                width: 100%;
                // height: calc(100vh);
                // object-fit:cover;
            }
        }

        // 校园新闻
        .news {
            width: 100%;
            height: 915px;

            box-sizing: border-box;

            .news_item {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
            }

            .newbg {
                position: absolute;
                width: 100%;
                top: 0;
                height: 502px;
                background: url("../assets/img/newsbg.png") no-repeat center/cover;
                z-index: -1;
                padding-top: 118px;
                box-sizing: border-box;
            }

            .more {
                width: 95px;
                font-weight: 400;
                font-size: 16px;
                text-align: center;
                color: #bea37e;
                border-bottom: 1px solid #bea37e;
                padding-bottom: 7px;
                margin-top: 150px;
                cursor: pointer;
                margin: 0 auto;
                white-space: nowrap;
            }

            .box {
                width: 429px;
                // padding-right: 60px;
                cursor: pointer;
                padding: 20px;
            }

            img {
                width: 100%;
                height: 304px;

                box-shadow: 1px 5px 20px 0 #000;
                transition: all 0.5s;

                &:hover {
                    transform: scale(1.05);
                    // tranform:scaleX(0.5)
                }
            }

            .date {
                width: 430px;
                font-family: "Microsoft YaHei";
                font-weight: 400;
                font-size: 16px;
                line-height: 36px;
                text-align: left;
                color: #2a2a2a;
                margin-top: 10px;
            }

            .Introduction {
                width: 430px;
                font-family: "Microsoft YaHei Bold";
                font-weight: 700;
                font-size: 20px;
                line-height: 36px;
                text-align: left;
                color: #2a2a2a;
            }

            .swiper_wai {
                width: 1432px;
                // background: #000;
                // height: 430px;
                display: flex;
                // flex-wrap: nowrap;
                position: relative;
                overflow: hidden;
                padding: 20px;
                margin-top: 180px;

                .swiper_nei {
                    // box-sizing: border-box;
                    padding-left: 15px;
                    display: flex;
                    position: absolute;
                    left: 0;
                    top: 0;
                    transition: all 1s;
                }
            }

            .title {
                font-family: "Microsoft YaHei Bold";
                font-weight: 700;
                font-size: 36px;
                letter-spacing: 0.2px;
                line-height: 30px;
                text-align: center;
                color: #134641;
            }

            .titled {
                position: absolute;
                top: 90px;
                left: 50%;
                font-family: "Microsoft YaHei";
                font-weight: 400;
                font-size: 38px;
                line-height: 30px;
                text-align: center;
                color: #4d3900;
                opacity: 0.3;
                z-index: -1;
                transform: translateX(-50%);
            }
        }

        // 校园课程
        .kecheng {
            width: 100%;
            height: 633px;
            background: #f6f6f6;
            position: relative;
            box-sizing: border-box;
            padding-top: 118px;

            .imgs {
                display: flex;
                justify-content: space-between;
                padding: 0 180px;
                box-sizing: border-box;
                margin-top: 56px;

                .item {
                    width: 450px;
                    height: 293px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    &:hover {
                        box-shadow: 1px 5px 20px 0 #000;
                    }

                    .text {
                        position: absolute;
                        z-index: 1;

                        .c-title {
                            font-family: "Microsoft YaHei Bold";
                            font-weight: 700;
                            font-size: 24px;
                            text-align: center;
                            color: #fff;
                        }

                        .c-titleen {
                            font-family: "Microsoft YaHei";
                            font-weight: 400;
                            font-size: 16px;
                            text-align: center;
                            color: #fff;
                        }
                    }

                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                    }
                }

                //     img {
                //         width: 450px;
                //         height: 293px;
                //         &:hover {
                //             box-shadow: 1px 5px 20px 0 #000;
                //             cursor: pointer;
                //         }
                //     }
            }

            .title {
                font-family: "Microsoft YaHei Bold";
                font-weight: 700;
                font-size: 36px;
                letter-spacing: 0.2px;
                line-height: 30px;
                text-align: center;
                color: #134641;
            }

            .titled {
                position: absolute;
                top: 90px;
                left: 50%;
                font-family: "Microsoft YaHei";
                font-weight: 400;
                font-size: 38px;
                line-height: 30px;
                text-align: center;
                color: #4d3900;
                opacity: 0.3;
                z-index: 0;
                transform: translateX(-50%);
            }
        }

        // 校园活动
        .activity {
            width: 100%;
            height: 1062px;
            background: url("../assets/bgbg.png") no-repeat center/cover;
            position: relative;
            box-sizing: border-box;
            padding: 0 215px;
            padding-top: 118px;

            .calendar {
                width: 95px;
                font-size: 20px;
                text-align: center;
                color: #bea37e;
                margin: 0 auto;
                padding-bottom: 7px;
                margin-top: 21px;
                border-bottom: 1px solid #BEA37E;
                margin-bottom: 73px;
                cursor: pointer;
            }

            .title {
                font-family: "Microsoft YaHei Bold";
                font-weight: 700;
                font-size: 36px;
                letter-spacing: 0.2px;
                line-height: 30px;
                text-align: center;
                color: #134641;

            }

            .activity_box {
                display: flex;
                justify-content: space-between;

                .imgs {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 0;
                }

                .two {
                    position: absolute;
                    font-weight: 700;
                    font-size: 20px;
                    color: #000;
                    top: -40px;
                }

                .left_box {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .one {
                        width: 369px;
                        height: 302px;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }
                }

                .mid_box {
                    width: 660px;
                    height: 621px;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .right_box {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .one {
                        width: 369px;
                        height: 302px;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }
                }
            }

            .titled {
                position: absolute;
                top: 90px;
                left: 50%;
                font-family: "Microsoft YaHei";
                font-weight: 400;
                font-size: 38px;
                line-height: 30px;
                text-align: center;
                color: #fff;
                color: #4d3900;
                opacity: 0.3;
                z-index: 0;
                transform: translateX(-50%);
            }
        }
    }

    // 移动
    .home_mobile {
        .swiper {
            // width: 100vw;
            height: 300px;
            display: flex;

            // overflow: hidden;
            .img {
                width: 100%;
                // height: calc(100vh);
                // object-fit:cover;
            }
        }

        // 校园新闻
        .news {
            width: 100%;
            // height: 1800px;
            position: relative;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;

            .newbg {
                position: absolute;
                width: 100%;
                top: 0;
                height: 177px;
                background: url("../assets/img/newsbg.png") no-repeat center/cover;
                z-index: -1;
                padding-top: 30px;
                box-sizing: border-box;
            }

            .swiper_wai {
                width: 100%;
                // background: #000;
                // height: 430px;
                display: flex;
                // flex-wrap: nowrap;
                overflow: hidden;
                margin-top: 120px;


                .box {
                    width: 100%;
                    padding: 3px;
                    cursor: pointer;
                }

                img {
                    width: 100%;


                    box-shadow: 1px 5px 20px 0 #000;
                    transition: all 0.5s;

                    &:hover {
                        transform: scale(1.1);
                        // tranform:scaleX(0.5)
                    }
                }

                .date {
                    // width: 830px;
                    font-family: "Microsoft YaHei";
                    font-weight: 400;
                    font-size: 14px;
                    // line-height: 76px;
                    text-align: left;
                    color: #2a2a2a;
                    margin-top: 10px;
                }

                .Introduction {
                    width: 100%;
                    font-family: "Microsoft YaHei Bold";
                    font-weight: 600;
                    font-size: 15px;

                    text-align: left;
                    color: #2a2a2a;
                }

            }

            .title {
                font-family: "Microsoft YaHei Bold";
                font-weight: 700;
                font-size: 26px;
                letter-spacing: 0.2px;
                line-height: 30px;
                text-align: center;
                color: #134641;
            }

            .titled {
                position: absolute;
                top: 10px;
                left: 50%;
                font-family: "Microsoft YaHei";
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                color: #848484;
                opacity: 0.1;
                transform: translateX(-50%);
            }
        }

        // 校园课程
        .kecheng {
            width: 100%;
            // height: 633px;
            background: #f6f6f6;
            position: relative;
            box-sizing: border-box;
            padding-top: 50px;
            padding-bottom: 118px;
            padding: 0 10px;
            margin-top: 20px;

            .imgs {
                display: flex;
                justify-content: space-between;
                padding: 0px;
                box-sizing: border-box;
                margin-top: 56px;
                flex-direction: column;

                .item {
                    width: 100%;
                    height: 250px;
                    position: relative;
                    display: flex;

                    align-items: center;
                    justify-content: center;
                    margin-top: 15px;
                    cursor: pointer;

                    &:hover {
                        box-shadow: 1px 5px 20px 0 #000;
                    }

                    .text {
                        position: absolute;
                        z-index: 1;

                        .c-title {
                            font-family: "Microsoft YaHei Bold";
                            font-weight: 700;
                            font-size: 24px;
                            text-align: center;
                            color: #fff;
                        }

                        .c-titleen {
                            font-family: "Microsoft YaHei";
                            font-weight: 400;
                            font-size: 20px;
                            text-align: center;
                            color: #fff;
                        }
                    }

                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                    }
                }

                //     img {
                //         width: 450px;
                //         height: 293px;
                //         &:hover {
                //             box-shadow: 1px 5px 20px 0 #000;
                //             cursor: pointer;
                //         }
                //     }
            }

            .title {
                font-family: "Microsoft YaHei Bold";
                font-weight: 700;
                font-size: 26px;
                letter-spacing: 0.2px;
                line-height: 30px;
                text-align: center;
                color: #134641;
            }

            .titled {
                position: absolute;
                top: 14px;
                left: 50%;
                font-family: "Microsoft YaHei";
                font-weight: 400;
                font-size: 20px;
                line-height: 23px;
                text-align: center;
                color: #848484;
                opacity: 0.1;
                transform: translateX(-50%);
            }
        }

        // 校园活动
        .activity {
            width: 100%;

            background: url("../assets/bgbg.png") no-repeat center/cover;
            position: relative;
            box-sizing: border-box;
            padding-top: 58px;
            padding: 15px 15px;
            box-sizing: border-box;
            .calendar {
                width: 95px;
                font-size: 20px;
                text-align: center;
                color: #bea37e;
                margin: 0 auto;
                padding-bottom: 7px;
                margin-top: 21px;
                border-bottom: 1px solid #BEA37E;
                margin-bottom: 73px;
                cursor: pointer;
            }
            .activity_box {
                display: flex;
                flex-direction: column;
                margin-top: 60px;

                .imgs {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 0;
                }

                .two {
                    position: absolute;
                    font-weight: 700;
                    font-size: 20px;
                    color: #000;
                    top: -40px;
                }

                .left_box {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .one {
                        width: 369px;
                        height: 302px;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }
                }

                .mid_box {
                    width: 100%;
                    height: 385px;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .right_box {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .one {
                        width: 369px;
                        height: 302px;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }
                }
            }

            .title {
                font-family: "Microsoft YaHei Bold";
                font-weight: 700;
                font-size: 24px;
                letter-spacing: 0.2px;
                line-height: 30px;
                text-align: center;
                color: #134641;
            }

            .titled {
                position: absolute;
                top: 30px;
                left: 50%;
                font-family: "Microsoft YaHei";
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                color: #fff;
                z-index: 0;
                opacity: 0.6;
                transform: translateX(-50%);
            }
        }
    }
</style>