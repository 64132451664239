<template>
    <div class="content" v-if="!isMobile_pc">
        <div class="img">
            <img style="width:100%;height:100%" src="../../assets/logoIcon552.png" alt="">
        </div>
        <div class="item">
            <div class="item_item">
                <div class="cn">中国灵魂</div>
                <div class="en">CHINESE SOUL</div>
            </div>
            <div class="item_item">
                <div class="cn">世界眼光</div>
                <div class="en">GLOBAL VIEW</div>
            </div>
            <div class="item_item">
                <div class="cn">国际情怀</div>
                <div class="en">INTERNATIONAL MIND</div>
            </div>
        </div>
        <div class="item1">
            <div class="item_item" v-for="(item,index) in dataInfo" :key="index">
                <!-- <img :src="Url + item.img" alt=""> -->
            </div>

        </div>
        <div class="cateclass">
            <div class="cate_item" v-for="(item,index) in cate" :key="index">
                <div class="title" @click="gomulu(index,item.url)">{{ item.name }}</div>

                <div v-for="(items,indexs) in item._child" :key="indexs" style="margin-top:10px;"
                    @click="sonClick(indexs,items.url,index)">
                    {{ items.name }}
                </div>
            </div>
        </div>
        <div class="banquan">
            {{info.copyright}} <a href="https://beian.miit.gov.cn" target="_blank">{{info.record_no}}</a>
        </div>

    </div>
    <div class="mobile_content" v-else>
        <div class="img">
            <img style="width:100%;height:100%" src="../../assets/logoIcon552.png" alt="">
        </div>
        <div class="item">
            <div class="item_item">
                <div class="cn">中国灵魂</div>
                <div class="en">CHINESE SOUL</div>
            </div>
            <div class="item_item">
                <div class="cn">世界眼光</div>
                <div class="en">GLOBAL VIEW</div>
            </div>
            <div class="item_item">
                <div class="cn">国际情怀</div>
                <div class="en">INTERNATIONAL MIND</div>
            </div>
        </div>
        <div class="item1">
            <div class="item_item" v-for="(item,index) in dataInfo" :key="index">
                <!-- <img :src="Url + item.img" alt=""> -->
            </div>

        </div>
        <div class="cateclass">
            <div class="cate_item" v-for="(item,index) in cate" :key="index">
                <div class="title" @click="gomulu(index,item.url)">{{ item.name }}</div>

                <div v-for="(items,indexs) in item._child" :key="indexs" style="margin-top:10px;"
                    @click="sonClick(indexs,items.url,index)">
                    {{ items.name }}
                </div>
            </div>
        </div>
        <div class="banquan">
            {{info.copyright}} <a href="https://beian.miit.gov.cn" target="_blank">{{info.record_no}}</a>
        </div>

    </div>
</template>

<script>
    import {
        getCollocation,
        getLink,
        getNavbarFooter,
    } from "@/network/test-api.js";
    export default {
        data() {
            return {
                info: {},
                dataInfo: [],
                Url: "",
                cate: [],
            };
        },
        created() {
            this.$VueTool.isMobile_pc() ?
                (this.isMobile_pc = true) :
                (this.isMobile_pc = false);
            this.Url = this.$BaseUrl.baseUrl.prod;

            let datas1 = new FormData();

            if (sessionStorage.getItem("lang")) {
                datas1.append("lang_f", sessionStorage.getItem("lang"));
            } else {
                datas1.append("lang_f", "Cn");
            }
            getCollocation(datas1)
                .then((res) => {
                    if (res.status == 1) {
                        this.info = res.row;
                    }
                })
                .catch((err) => {});

            getLink(datas1)
                .then((res) => {
                    if (res.status == 1) {
                        this.dataInfo = res.row;
                    }
                })
                .catch((err) => {});

            getNavbarFooter(datas1).then((res) => {
                if (res.status == 1) {
                    this.cate = res.row;
                }
            });
        },
        methods: {
            sonClick(index, id, nav) {
                let url;
                if (nav == 0) {
                    nav = 1;
                    url = "/admissionsView";
                } else if (nav == 1) {
                    nav = 2;
                    url = "/courseView";
                } else if (nav == 2) {
                    nav = 3;
                    url = "/serviceView";
                } else if (nav == 3) {
                    nav = 4;
                    url = "/newsView";
                } else if (nav == 4) {
                    nav = 5;
                    url = "/activityView";
                } else if (nav == 5) {
                    nav = 6;
                    url = "/joinView";
                } else if (nav == 6) {
                    nav = 7;
                    url = "/about";
                }

                console.log("触发到子级", index, id, nav);
                sessionStorage.setItem("navindex", nav);
                sessionStorage.setItem("twocateindex", index);
                sessionStorage.setItem("cateId", id);
                this.$store.commit("change", nav);
                this.$store.commit("changetwo", index);
                this.$store.commit("changeCateId", id);
                this.$router.replace(url);
                // this.$router.go(0)
                // chrome
                document.body.scrollTop = 0;
                // firefox
                document.documentElement.scrollTop = 0;
                // safari
                window.pageYOffset = 0;
            },

            gomulu(index, url) {
                console.log(index);
                if (index == 0) {
                    this.$router.replace(url);
                    sessionStorage.setItem("navindex", 1);
                    this.$store.commit("change", 1);
                } else if (index == 1) {
                    this.$router.replace(url);
                    sessionStorage.setItem("navindex", 3);
                    this.$store.commit("change", 3);
                } else if (index == 2) {
                    this.$router.replace(url);
                    sessionStorage.setItem("navindex", 4);
                    this.$store.commit("change", 4);
                } else if (index == 3) {
                    this.$router.replace(url);
                    sessionStorage.setItem("navindex", 5);
                    this.$store.commit("change", 5);
                } else if (index == 4) {
                    this.$router.replace(url);
                    sessionStorage.setItem("navindex", 6);
                    this.$store.commit("change", 6);
                } else if (index == 5) {
                    this.$router.replace(url);
                    sessionStorage.setItem("navindex", 7);
                    this.$store.commit("change", 7);
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .content {
        width: 100%;
        // height: 487px;
        background: url("../../assets/footBG.png") no-repeat center/cover;
        // padding: 0 2.2rem;
        box-sizing: border-box;
        padding-bottom: 69.44px;

        .img {
            width: 483px
                /* 552/10 */
                /* 36.4/10 */
            ;
            margin: 0 auto;
            padding-top: 56px;
            box-sizing: border-box;
        }

        .item {
            display: flex;
            justify-content: center;
            margin-top: 36px;
            flex-wrap: wrap;
            margin-bottom: 36px;

            .item_item {
                margin-left: 90px;
            }

            .cn {
                font-family: "HarmonyOS_Sans_SC ";
                font-weight: 400;
                font-size: 27.89px;
                text-align: center;
                color: #fff;
            }

            .en {
                font-family: "HarmonyOS_Sans_SC ";
                font-weight: 400;
                font-size: 21.9px;
                text-align: center;
                color: #a7ad86;
            }
        }

        .item1 {
            display: flex;
            justify-content: center;

            flex-wrap: wrap;
            // width: 667px;
            margin: 0 auto;

            .item_item {
                // width: 112px;
                margin-left: 90px;

                img {
                    // width: 100%;
                }
            }

            .cn {
                font-family: "HarmonyOS_Sans_SC ";
                font-weight: 400;
                font-size: 27.89px;
                text-align: center;
                color: #fff;
            }

            .en {
                font-family: "HarmonyOS_Sans_SC ";
                font-weight: 400;
                font-size: 21.9px;
                text-align: center;
                color: #a7ad86;
            }
        }

        .banquan {
            font-family: "MicrosoftYaHei ";
            font-weight: 400;
            font-size: 16px;
            
            text-align: center;
            color: #fff;
            margin-top: 45px;

            a {
                // text-decoration: none;
                color: inherit;
            }
        }

        .cateclass {
            width: 80%;
            border-top: 1px solid #fff;
            margin: 0 auto;
            margin-top: 30px;
            display: flex;
            justify-content: space-evenly;
            padding-top: 60px;

            .cate_item {
                cursor: pointer;

                .title {
                    color: #fff;
                    font-weight: bold;
                    font-size: 14px;
                }

                display: flex;
                flex-direction: column;
                color: #848a96;
                font-size: 14px;
            }
        }
    }


    .mobile_content {
        width: 100%;
        // height: 487px;
        background: url("../../assets/footBG.png") no-repeat center/cover;
        // padding: 0 2.2rem;
        box-sizing: border-box;
        padding-bottom: 69.44px;

        .img {
            // width: 483px
                /* 552/10 */
                /* 36.4/10 */
            // ;
            margin: 0 auto;
            padding-top: 56px;
            box-sizing: border-box;
        }

        .item {
            display: flex;
            justify-content: center;
            margin-top: 36px;
            flex-wrap: wrap;
            margin-bottom: 36px;

            .item_item {
                margin-left: 0px;
            }

            .cn {
                font-family: "HarmonyOS_Sans_SC ";
                font-weight: 400;
                font-size: 27.89px;
                text-align: center;
                color: #fff;
            }

            .en {
                font-family: "HarmonyOS_Sans_SC ";
                font-weight: 400;
                font-size: 21.9px;
                text-align: center;
                color: #a7ad86;
            }
        }

        .item1 {
            display: flex;
            justify-content: center;

            flex-wrap: wrap;
            // width: 667px;
            margin: 0 auto;

            .item_item {
                // width: 112px;
                margin-left: 90px;

                img {
                    // width: 100%;
                }
            }

            .cn {
                font-family: "HarmonyOS_Sans_SC ";
                font-weight: 400;
                font-size: 27.89px;
                text-align: center;
                color: #fff;
            }

            .en {
                font-family: "HarmonyOS_Sans_SC ";
                font-weight: 400;
                font-size: 21.9px;
                text-align: center;
                color: #a7ad86;
            }
        }

        .banquan {
            font-family: "MicrosoftYaHei ";
            font-weight: 400;
            font-size: 14px;

            text-align: center;
            color: #fff;
            margin-top: 45px;

            a {
                // text-decoration: none;
                color: inherit;
            }
        }

        .cateclass {
            width: 80%;
            border-top: 1px solid #fff;
            margin: 0 auto;
            margin-top: 30px;
            display: grid;
            grid-template-columns: repeat(4,1fr);
            gap:10px;
            padding-top:20px;

            .cate_item {
                cursor: pointer;

                .title {
                    color: #fff;
                    font-weight: bold;
                    font-size: 14px;
                }

                display: flex;
                flex-direction: column;
                color: #848a96;
                font-size: 14px;
            }
        }
    }
</style>