 // 判断是在手机还是电脑
 function isMobile_pc() {
    let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
return flag;
};

module.exports =  {
isMobile_pc:isMobile_pc
}