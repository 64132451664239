// 创建文件zh.js
const cn = {
    Home: '首页',
    Admissions: '招生',
    Course:'课程',
    Service:'服务',
    news:'校园新闻',
    Activity:'校园活动',
    Join:'加入我们',
    About:'关于我们',
    Contact:'联系我们',
    language: {
        cn: 'Cn',
        en: 'En',
    },
    isCN:'true',
    back:'返回列表',
    detail:'查看详情',
    source:'来源',
    pre:'上一条',
    next:'下一条',
    join:'加入',
    tel:'咨询热线',
    tel2:'联系电话',
    tel3:'电子邮箱',
    tel4:'学校网址',
    tel5:'学校地址',
    tel6:'人事',
    tel7:'招聘',
    tel8:'扫一扫关注学校官方微信公众号',
    calendar:'学校日历'
}
export default cn;
